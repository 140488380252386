import React from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../ErrorComponent'


const Contact = () => {

  const { t, ready } = useTranslation()


  return (
    <ErrorBoundary errorComponent={({ error }) => ErrorComponent('ContactCustom', error)}>
      <>
        {ready &&
          <section id="contact" className="payment-processing-contact">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="contact-connect-custom">
                    <h3 style={{ marginBottom: '20px' }}>{t('Ready to go?')}</h3>
                    <Link href="/" passHref legacyBehavior>
                      <a className="btn btn-primary">
                        {t('Book Vehicle')}
                      </a>
                    </Link>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="contact-connect-custom vertical-spacing-mobile">
                    <h3 style={{ marginBottom: '20px' }}>{t('Have a question?')}</h3>
                    <Link href="/faq" passHref legacyBehavior>
                      <a className="btn btn-primary">
                        {t('Read our FAQ')}
                      </a>
                    </Link>
                    <a href="mailto:rentals@simplybook.com" className="talk-to-sales">
                      rentals@simplybook.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>}
      </>
    </ErrorBoundary>
  )
}

export default Contact
