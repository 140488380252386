import { useTranslation } from 'next-i18next'
import SearchTabs from '../../SearchTabs'

import React, { FunctionComponent } from 'react'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../ErrorComponent'


const Banner: FunctionComponent = () => {
  const { t, ready } = useTranslation()
  return (
    <ErrorBoundary errorComponent={({ error }) => ErrorComponent('Banner', error)}>
      {ready &&
        <div id="home" className="lead-generation-banner jarallax" data-jarallax='{"speed": 0.2}'>
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-5 col-md-12">
                    <div className="lead-generation-form">
                      <SearchTabs />
                    </div>
                  </div>
                  <div className="col-lg-6 offset-lg-1 col-md-12">
                    <div className="lead-generation-banner-content ">
                      <h2>
                        <span>{t('Rent a motorbike, quad, or car from local rentals in Greece')}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </ErrorBoundary>
  )
}

export default Banner


