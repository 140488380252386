import Link from 'next/link'
import Image from 'next/image'
import scrollToTop from '../../../utils/scrollToTop'

import React, { FunctionComponent } from 'react'
import { useTranslation } from 'next-i18next'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../ErrorComponent'


const VehiclesVariety: FunctionComponent = () => {
  const { t, ready } = useTranslation()


  return (
    <ErrorBoundary errorComponent={({ error }) => ErrorComponent('VehiclesVariety', error)}>
      <>
        {ready &&
          <section
            className="cta-area cta-home-screen ptb-100 jarallax pb-0"
            data-jarallax='{"speed": 0.2}'
          >
            <div className="container">
              <div className="cta-content">
                <h2>{t('Choose from a variety of vehicles & enjoy your next holidays in Greece!')}</h2>
              </div>

              <div className="cta-inner-content">
                <div className="row">
                  <Link legacyBehavior href={{ pathname: '/motorbike' }}>
                    <div
                      className="col-lg-4 col-md-6 offset-lg-0 offset-md-3"
                      onClick={() => scrollToTop()}
                    >
                      <div className="single-cta-box single-cta-home-box">
                        <Image
                          src="/images/assets/green-motorbike.jpeg"
                          alt="Green Motorbike"
                          sizes="100vh"
                          width="300"
                          height="200"
                          style={{ width: '100%', height: 'auto' }}
                        />
                        <h3>{t('MOTORBIKES')}</h3>
                        <p />
                      </div>
                    </div>
                  </Link>

                  <Link legacyBehavior href={{ pathname: '/atv' }}>
                    <div className="col-lg-4 col-md-6" onClick={() => scrollToTop()}>
                      <div className="single-cta-box single-cta-home-box">
                        <Image
                          src="/images/assets/yellow-atv.jpeg"
                          alt="Yellow atv"
                          sizes="100vh"
                          width="300"
                          height="200"
                          style={{ width: '100%', height: 'auto' }}
                        />
                        <h3>{t('ATV QUADS')}</h3>
                        <p />
                      </div>
                    </div>
                  </Link>

                  <Link legacyBehavior href={{ pathname: '/car' }}>
                    <div className="col-lg-4 col-md-6" onClick={() => scrollToTop()}>
                      <div className="single-cta-box single-cta-home-box">
                        <Image
                          src="/images/assets/blue-car.jpeg"
                          alt="Blue Car"
                          sizes="100vh"
                          width="300"
                          height="200"
                          style={{ width: '100%', height: 'auto' }}
                        />
                        <h3>{t('CARS')}</h3>
                        <p></p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        }
      </>
    </ErrorBoundary>)
}

export default VehiclesVariety
